'use strict';

/**
 * 
 * Form validation - success/error/redirect/or just message
 * jQuery dependency needed
 * HTML: onsubmit="return XXX._form(this);" and loading layer inside form <div class="loading"></div>
 */

var UTILS = UTILS || {};

const loadScript = (src, callback) => {
    let s; let r; let t;

    r = false;
    s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = src;
    s.onload = s.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState === 'complete')) {
            r = true;

            if (callback) {
                callback();
            }
        }
    };
    t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
};

const setGoogleRecaptchaTokenInForm = (form, callback) => {
    if (!form) {
        return;
    }
  
    const recaptchaFieldElement = form.querySelector('input[name="recaptcha"]');
  
    if (!recaptchaFieldElement) {
        return;
    }

    if (recaptchaFieldElement.value) {
        return;
    }

    setTimeout(() => {
        grecaptcha
            .execute(googleRecaptchaClientId, { action: '/ajax/sendForm' })
            .then((token) => {
                recaptchaFieldElement.value = token;

                if (callback) {
                    callback();
                }
            });
    }, 250);
};

UTILS.recaptcha = {
    isRecaptchaLoaded: function() {
        return !((typeof grecaptcha === 'undefined' || grecaptcha === null));
    },
    doesRecaptchaClientIdExist: function() {
        return (!((typeof googleRecaptchaClientId === 'undefined' || googleRecaptchaClientId === null)));;
    },
    init: function() {
        const forms = document.querySelectorAll('form');

        console.log(UTILS.recaptcha.isRecaptchaLoaded(), UTILS.recaptcha.doesRecaptchaClientIdExist());

        if (UTILS.recaptcha.isRecaptchaLoaded() || !UTILS.recaptcha.doesRecaptchaClientIdExist()) {
          return;
        }
      
        forms.forEach((form) => {
          form.addEventListener('click', () => {
            if (!UTILS.recaptcha.isRecaptchaLoaded()) {
              loadScript(`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaClientId}`, function() {
                setGoogleRecaptchaTokenInForm(form);
              });
            } else {
                setGoogleRecaptchaTokenInForm(form);
            }
          });
        });
    }
};