'use strict';

let CH = CH || {};
let UTILS = UTILS || {};
let static_folder = '/images';

CH.utils = UTILS || {};
CH.menu = {};

CH.utils.inputLabels = function inputLabels() {
    function placeholderPolyfill(el) {
        this.classList[this.value ? 'add' : 'remove']('filled');
    }

    if (document.querySelector('input')) {
        var allInputs = document.querySelectorAll('input');
        var allTextareas = document.querySelectorAll('textarea');
        var allSelects = document.querySelectorAll('select');
        console.log('inputsLabels input');
        [].forEach.call(allInputs, function (singleInput) {
            singleInput.classList[singleInput.value ? 'add' : 'remove']('filled');
            singleInput.addEventListener('change', placeholderPolyfill);
            singleInput.addEventListener('keyup', placeholderPolyfill);
        });

        [].forEach.call(allTextareas, function (singleTextarea) {
            singleTextarea.classList[singleTextarea.value ? 'add' : 'remove']('filled');
            singleTextarea.addEventListener('change', placeholderPolyfill);
            singleTextarea.addEventListener('keyup', placeholderPolyfill);
        });

        [].forEach.call(allSelects, function (singleSelect) {
            singleSelect.classList[singleSelect.value ? 'add' : 'remove']('filled');
            singleSelect.addEventListener('change', placeholderPolyfill);
            singleSelect.addEventListener('keyup', placeholderPolyfill);
        });
    }
};

CH.scrollTopPosition = $(window).scrollTop();
CH.navbarPosition = {

    ie: function() {
        $(window).bind('mousewheel', function(event) {
            if (event.originalEvent.wheelDelta >= 0) {
                //Scroll up
                document.querySelector('.js-navbar-scroll').classList.remove('l-navbar--scrolled');
                document.body.classList.remove('page-scrolled');
            }
            else {
                //Scroll down
                document.querySelector('.js-navbar-scroll').classList.add('l-navbar--scrolled');
                document.body.classList.add('page-scrolled');
            }
    
            CH.scrollTopPosition = scroll;
        });
    },

    allBrowsers: function() {
        scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        if (document.querySelector('.js-navbar-scroll')) {
            if (scroll >= CH.scrollTopPosition && CH.scrollTopPosition > 100) {
                document.querySelector('.js-navbar-scroll').classList.add('l-navbar--scrolled');
                document.body.classList.add('page-scrolled');
            } else {
                document.querySelector('.js-navbar-scroll').classList.remove('l-navbar--scrolled');
                document.body.classList.remove('page-scrolled');
            }
        }

        CH.scrollTopPosition = scroll;
    },
};

CH.utils.isConfirmPassword = function isConfirmPassword() {
    if (document.querySelector('.js-confirm-password')) {
        let password = document.querySelector('.js-password'),
            confirmPassword = document.querySelector('.js-confirm-password');

        password.onchange = CH.utils.validatePassword;
        confirmPassword.onkeyup = CH.utils.validatePassword;

    }
}

CH.utils.validatePassword = function validatePassword() {
    if (document.querySelector('.js-confirm-password')) {
        let password = document.querySelector('.js-password'),
            confirmPassword = document.querySelector('.js-confirm-password');

        if (password.value.length > 0 && confirmPassword.value.length > 0) {
            if (password.value !== confirmPassword.value) {
                confirmPassword.setCustomValidity("Passwords Don't Match");
                confirmPassword.reportValidity();
            } else {
                confirmPassword.setCustomValidity('');
            }
        }
    }
};

CH.utils.svgFit = function svgFit() {
    var svg = document.querySelector('#svgAnimation'),
        originH = svg.getAttribute('data-nominal-height')*1,
        originW = svg.getAttribute('data-nominal-width')*1,
        actualWidth =  $(svg).parent().outerWidth(),
        actualHeight =  $(svg).parent().outerHeight(),

        screenRatio,
        svgRatio,
        newSvgHeight,
        newSvgWidth;

    screenRatio = actualWidth / actualHeight;
    svgRatio = originW / originH;

    newSvgHeight = svgRatio * actualHeight;
    newSvgWidth = svgRatio * actualWidth;

    if (screenRatio < svgRatio) {
        newSvgWidth = actualWidth;
        newSvgHeight = (originH * newSvgWidth) / originW;
    } else {
        newSvgHeight = actualHeight;
        newSvgWidth = (newSvgHeight * originW) / originH;
    }

    svg.setAttribute('width', newSvgWidth );
    svg.setAttribute('height', newSvgHeight );

};

CH.utils.homepageAnimation = function homepageAnimation() {

    if (document.querySelector('.l-header__col-scroll')) {
        let headerColScroll = document.querySelector('.l-header__col-scroll'),
            headerHeadline = document.querySelector('.l-header__headline')

        headerColScroll.classList.add('show');
        headerHeadline.classList.add('show');
    }

    if (document.querySelector('#svgAnimation')) {
        CH.utils.svgFit();

        // let morphTime = 800,
        //     drawTime = 1500;

        let morphTime = 1200,
            drawTime = 2700;

        var multiMorph1 = KUTE.fromTo('#svg-countries1', { path: '#svg-countries1' }, { path: '#svg-logo2' }, {morphPrecision: 20, duration: morphTime, delay: 0, repeat: 0, yoyo: false, easing: 'easingSinusoidalInOut'});
        var multiMorph2 = KUTE.fromTo('#svg-countries2', { path: '#svg-countries2' }, { path: '#svg-logo3' }, {morphPrecision: 20, duration: morphTime, delay: 0, repeat: 0, yoyo: false, easing: 'easingSinusoidalInOut'});
        var multiMorph3 = KUTE.fromTo('#svg-countries3', { path: '#svg-countries3' }, { path: '#svg-logo1' }, {morphPrecision: 20, duration: morphTime, delay: 0, repeat: 0, yoyo: false, easing: 'easingSinusoidalInOut'});

        let svg = document.querySelector('#svgAnimation');
        var wholeAnimation1 = KUTE.fromTo(
            "#svg-countries1",
            { draw: "0% 0%" },
            { draw: "0% 100%" },
            { duration: drawTime}
        );

        var wholeAnimation2 = KUTE.fromTo(
            "#svg-countries2",
            { draw: "0% 0%" },
            { draw: "0% 100%" },
            { duration: drawTime}
        );

        var wholeAnimation3 = KUTE.fromTo(
            "#svg-countries3",
            { draw: "0% 0%" },
            { draw: "0% 100%" },
            { duration: drawTime}
        );
   
        setTimeout(function(){
            wholeAnimation1.start();
            wholeAnimation2.start();
            wholeAnimation3.start();

            setTimeout(function(){
                multiMorph1.start();
                multiMorph2.start();
                multiMorph3.start();
            }, drawTime + 300);

            setTimeout(function(){
                let headerImage = document.querySelector('.l-header__img--curtain');
                $(svg).addClass('hide');
                // headerImage.classList.add('show');
                try {
                    $(headerImage).addClass('show');
                } catch(e) {
                    null
                }
                
            }, drawTime + 300 + morphTime + 200 + 500);

        }, 1000);
    }
};

CH.utils.isSubmenu = function isSubmenu() {
    if (document.querySelector('.l-navbar')) {
        const navbar = document.querySelector('.l-navbar'),
            isSubmenuOnPage = document.querySelectorAll('.l-submenu');

        if (isSubmenuOnPage.length) {
            navbar.classList.add('l-navbar--submenu');
            document.body.classList.add('has-submenu');
            $(document).on('scroll', CH.utils.submenuOnScroll);
        } else {
            navbar.classList.remove('l-navbar--submenu');
            document.body.classList.remove('has-submenu');
        }
    }
};

CH.utils.submenuOnScrollLinkArray = [];

CH.utils.fillSubmenuLinksArray = function fillSubmenuLinksArray() {
    $('.l-submenu__list-link').each(function () {
        let currLink = $(this),
            refElement = currLink.attr("href"),
            offset = undefined;

        try {
            offset = $(refElement).offset();
        } catch(e) {
            null
        }

        if (offset) {
            CH.utils.submenuOnScrollLinkArray.push(currLink);
        }

    });
};

CH.utils.submenuOnScroll = function submenuOnScroll(event) {
    var scrollPos = $(document).scrollTop();
    let isSubmenuOnPage = document.querySelectorAll('.l-submenu');

    if (isSubmenuOnPage.length) {
        $(CH.utils.submenuOnScrollLinkArray).each(function () {
            let currLink = $(this),
                refElement = currLink.attr("href"),
                offset = undefined,
                offsetTop = 60;

            offset = $(refElement).offset();

            if ( $(refElement).offset().top <= scrollPos + offsetTop  && $(refElement).offset().top + $(refElement).outerHeight() > scrollPos) {

                $('.l-submenu__list-link').removeClass("active");
                currLink.addClass("active");
            } else {
                currLink.removeClass("active");
            }
        });
    }
};

CH.utils.goToHash = function goToHash() {
    var path = window.location.hash,
        isOnPage = false;

    if (path !== '') {

        var splitHash = path.split('&');
        if ( splitHash.length === 1) {

            try {
                isOnPage = document.querySelector(path);
            } catch(e) {
                null
            }

            if (isOnPage) {
                CH.utils.scrollToID(path);
            }
        }
    }
};

CH.utils.confirmFormId = '';
CH.utils.confirmPopup = function confirmPopup(formId) {
    CH.utils.confirmFormId = formId;
    
    let popup = document.querySelector('.popup-wrapper-confirmation');

    popup.classList.add('popup-wrapper--show');
    return false;
};

CH.utils.popupClose = function popupCancel(event) {
    if (document.querySelector('.popup-wrapper-confirmation')) {
        let popup = document.querySelector('.popup-wrapper-confirmation'),
            popupContentCloseBtn = popup.querySelector('.popup__close');

        popupContentCloseBtn.addEventListener("click", function() {
            popup.classList.remove('popup-wrapper--show');
        });
    }
};

CH.utils.popupCancel = function popupCancel(event) {
    if (document.querySelector('.popup-wrapper-confirmation')) {
        let popup = document.querySelector('.popup-wrapper-confirmation');

        popup.querySelector('.js-no').addEventListener("click", function() {
            popup.classList.remove('popup-wrapper--show');
            return false;
        });
    }
};

CH.utils.popupConfirm = function popupCancel(event) {
    if (document.querySelector('.popup-wrapper-confirmation')) {
        let popup = document.querySelector('.popup-wrapper-confirmation');

        popup.querySelector('.js-yes').addEventListener("click", function() {
            let form = document.querySelector(`#${CH.utils.confirmFormId}`);
            form.submit();
        });
    }
};








CH.creditCard = {
    nameOnCard: function() {
        if (document.querySelector('.js-card-name-input')) {
            let cardInputName = document.querySelector('.js-card-name-input'),
                nameOnCard = document.querySelector('.js-card-name-target');

            if (!nameOnCard) {
                return;
            }

            cardInputName.addEventListener('keyup', function() {
                if (this.value.length > 0) {
                    nameOnCard.innerHTML = this.value;
                } else {
                    nameOnCard.innerHTML = nameOnCard.getAttribute('data-placeholder');
                }
            })
        }
    },

    numberOnCard: function() {
        if (document.querySelector('.js-card-number-input')) {
            let cardInputNumber = document.querySelector('.js-card-number-input'),
                numberOnCard = document.querySelector('.js-card-number-target');

            if (!numberOnCard) {
                return;
            }

            cardInputNumber.addEventListener('keyup', function() {
                numberOnCard.querySelector(`span`).innerHTML = '0000 0000 0000 0000';


                if (this.value.length > 0) {
                    numberOnCard.querySelector(`span`).innerHTML = this.value;
                } else {
                    numberOnCard.querySelector(`span`).innerHTML = '0000 0000 0000 0000';
                }
            });
        }
    },

    expiresOnCard: function() {
        if (document.querySelector('.js-card-expires-input')) {
            let cardInputExpires = document.querySelector('.js-card-expires-input'),
                expiresOnCard = document.querySelector('.js-card-expires-target');

            if (!expiresOnCard) {
                return;
            }

            cardInputExpires.addEventListener('keyup', function() {
                if (this.value.length) {
                    expiresOnCard.querySelector(`span`).innerHTML = this.value;
                }

                if (this.value.length === 0) {
                    expiresOnCard.querySelector(`span`).innerHTML = '00 / 00';
                }
            });
        }
    }
};

CH.filter = {
    wasFilterUse: false,

    changeValue: function(val) {
        let url = window.location.origin + '/' + val;
        CH.filter.wasFilterUse = true;
        Barba.Pjax.goTo( url );
        return false;
    },
};

CH.ajax = {
    contentUpdate: function contentUpdate(url, formId, targetId, callbackOnSuccess) {
        let form = `#${formId}`,
            target = `#${targetId}`,
            data = $(form).serialize();
        
        $(form).find('.loading').fadeIn();

        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            dataType: 'json'
        })
        .then(function(response) {
            $(form).find('.loading').fadeOut();
            document.querySelector(target).innerHTML = response.html;
    
            // remove error response if exists and reset the form
            $(form).find('.form__response--error').remove();
            $(form)[0].reset();

            if (callbackOnSuccess) {
                callbackOnSuccess();
            }
    
        }, function(response) {
            let errorMessage = '';
    
            if (response.status == 422) {
                for (var field in response.responseJSON) {
                    if (response.responseJSON.hasOwnProperty(field)) {
                        errorMessage += response.responseJSON[ field ].join('<br>') + '<br>';
                    }
                }
            } else if (response.status == 404) {
                errorMessage = 'Page not found - incorrect url.';
            } else if (response.responseJSON.message) {
                errorMessage = response.responseJSON.message;
            }
    
            if (errorMessage) {
                if ( $(form).find('.form__response--error').length === 0 ) {
                    $(form).prepend( '<div class="form__response form__response--error"><p>'+errorMessage+'</p></div>' );
                } else {
                    $('.form__response--error p').html(errorMessage);
                }
            }
    
            // remove success response if exists
            $(form).find('.form__response--ok').remove();
            $(form).find('.loading').fadeOut();
        });

    }
};



CH.selectBoxes = {
    init: function() {

        $('select:not(.js-select-redirection)').selectbox(
            {
                effect: 'slide',
                speed: 400,
                // onChange: function (val, inst) {
                //     // $(self).val(val).change();
                //     // $(self).find('option:first').prop( 'selected', true );

                //     return true;
                // },

                // onClose: function (inst) {
                //     if ( isAppleDevice() ) {
                //         let uid = inst.uid;
                //         let selectValue = $('#sbSelector_' + uid).text();
                //         $(inst.input[0]).val(selectValue);
                //         console.log( $(inst.input[0]).val() );
                //     }

                // },
                onLoaded: function() {
                    $('.filter--with-dropdown').addClass('show');
                }
            }
        );

        $('.js-select-redirection').each(function(index) {
            var self = this;
            $(self).css('display', 'none');
            $('.sbOptions a').attr('href', '');
            $(self).selectbox({
                onOpen: function (inst) {
                    var id = inst.uid,
                        selectedText = $('#sbSelector_'+id).text(),
                        options = $('#sbOptions_'+id+' li');

                    options.each(function(index) {
                        if ( $(this).find('a').text() === selectedText ) {
                            $(this).addClass('selected');
                        } else {
                            $(this).removeClass('selected');
                        }
                    });
                },
                onClose: function (inst) {

                },
                onChange: function (val, inst) {
                    $(self).val(val).change();

                    if (val !== '-1') {
                        window.location = val;
                    }
                    if (val === 0) {
                        $(self).parent().find('.sbHolder').removeClass('selected');
                    } else {
                        $(self).parent().find('.sbHolder').addClass('selected');
                    }
                },
                effect: "slide"
            });
        });
    }
};

CH.menu = {
    burgerElement: document.querySelector('.js-burger'),
    submenu: function () {
        if (document.querySelector('.js-submenu')) {

            var submenuButtons = document.querySelectorAll('.js-submenu');

            [].forEach.call(submenuButtons, function (submenuButton) {
                submenuButton.addEventListener("click", function (event) {
                    event.preventDefault();

                    CH.menu.closeAllSubmenus(submenuButton);

                    if (submenuButton.parentNode.classList.contains('open')) {
                        submenuButton.parentNode.classList.remove('open');

                        $(submenuButton.nextElementSibling).slideUp("slow");
                    } else {
                        $(submenuButton.nextElementSibling).slideDown("slow");

                        setTimeout(function () {
                            submenuButton.parentNode.classList.add('open');
                        }, 150);
                    }

                    return false;
                });
            });
        }
    },

    burger: function () {
        if (this.burgerElement) {

            const burgerButton = this.burgerElement,
                menuWrapper = document.querySelector('.l-navbar__menu-wrapper')

            burgerButton.addEventListener("click", function (event) {
                event.preventDefault();

                if (menuWrapper.classList.contains('open')) {
                    menuWrapper.classList.remove('open');

                    document.documentElement.classList.remove('nav-open');
                    document.body.classList.remove('nav-open');

                    $(menuWrapper).slideUp("slow");
                } else {
                    $(menuWrapper).slideDown("slow");

                    setTimeout(function () {
                        menuWrapper.classList.add('open');

                        document.documentElement.classList.add('nav-open');
                        document.body.classList.add('nav-open');
                    }, 150);
                }
            });
        }
    },

    closeSubmenuByHitEsc: function() {
        document.addEventListener('keyup', function( event ) {
            if (event.keyCode === 27) {
                CH.menu.closeAllSubmenus();
            }
        });
    },

    closeAllSubmenus: function (submenuButton = null) {
        let submenuButtons = document.querySelectorAll('.js-submenu');
        [].forEach.call(submenuButtons, function (submenuButtonRepeat) {

            if (submenuButton !== submenuButtonRepeat) {
                submenuButtonRepeat.parentNode.classList.remove('open');

                $(submenuButtonRepeat.nextElementSibling).slideUp("slow");
            }
        });
    },

    __closeSubmenuOutsideSubmenu (event) {
        var current = event.target,
            parent = current.parentNode,
            isSubmenuChild = false;

        if ( !current.classList.contains('js-submenu') ) {
            if (parent) {
                if (parent !== null) {
                    while ( parent.tagName !== 'HTML') {
                        if(current.classList) {
                            if (current.classList.contains('l-navbar__sub-menu') || current.classList.contains('js-search-target') ) {
                                isSubmenuChild = true;
                                break;
                            } else {
                                current = current.parentNode;
                                parent = parent.parentNode;
                            }
                        }
                    }
                }
            }

            isSubmenuChild === true ? false : CH.menu.closeAllSubmenus();
        }

    },

    closeSubmenuOutsideSubmenuListener () {
        document.addEventListener('click', CH.menu.__closeSubmenuOutsideSubmenu, false);
    },

    removeActiveManuItem () {
        let allMenuButtons = document.querySelectorAll('.l-navbar__menu-link');
        [].forEach.call(allMenuButtons, function (menuButtonSingle) {
            menuButtonSingle.classList.remove('active');
        });
    },

    setActiveMenuItemFromMainNav () {
        let menuButtons = document.querySelectorAll('a.l-navbar__menu-link');
        [].forEach.call(menuButtons, function (menuButton) {

            menuButton.addEventListener("click", function (event) {
                event.preventDefault();
                CH.menu.removeActiveManuItem();

                menuButton.classList.add('active');
                return true;
            });
        });
    },

    setActiveMenuItemFromSubNav () {
        let submenuButtons = document.querySelectorAll('.l-navbar__sub-menu-link');

        [].forEach.call(submenuButtons, function (submenuButton) {
            submenuButton.addEventListener("click", function (event) {
                event.preventDefault();

                let menuButtons = document.querySelectorAll('.l-navbar__menu-link');
                [].forEach.call(menuButtons, function (menuButton) {
                    menuButton.classList.remove('active');
                });

                let LImenuTag = submenuButton.parentNode.parentNode.parentNode;
                LImenuTag.querySelector('.l-navbar__menu-link').classList.add('active');

                CH.menu.closeAllSubmenus();

                return true;
            });
        });
    },

    closeMenuOnLinkClickOnMobile () {
        let menuLinks = document.querySelectorAll('.l-navbar a');
        [].forEach.call(menuLinks, function (link) {

            link.addEventListener("click", function (event) {
                event.preventDefault();

                if (document.documentElement.classList.contains('nav-open') && CH.menu.burgerElement) {
                    CH.menu.burgerElement.click();
                }
            });
        });
    },

    logo () {
        let logoNav = document.querySelector('.l-navbar__logo-link'),
            logoFooter = document.querySelector('.l-footer__logo-link');

        logoNav.addEventListener("click", function (event) {
            event.preventDefault();
            CH.menu.removeActiveManuItem();
            return true;
        });

        logoFooter.addEventListener("click", function (event) {
            event.preventDefault();
            CH.menu.removeActiveManuItem();
            return true;
        });
    }
};


CH.contact = {
    isMapLoaded: false,

    loadScript: function() {

        if (CH.contact.isMapLoaded === false) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyAWxwKr9ImHIBENkW4Ooq8TGEUZHsE4aVk&' +
                    'callback=CH.contact.map';
            document.body.appendChild(script);

            CH.contact.isMapLoaded = true;
        } else {
            CH.contact.map();
        }
    },

    map: function() {
        function init() {
                var draggable;
                var isMobile = function() {
                try{ document.createEvent("TouchEvent"); return true; }
                    catch(e){ return false; }
                };
                if(isMobile()) {
                    draggable = false;
                } else {
                    draggable = true;
                }

                var maps = document.querySelectorAll('.js-map');

                [].forEach.call(maps, function(mapObj) {

                    var myLatLngX = mapObj.getAttribute('data-latitude');
                    var myLatLngY = mapObj.getAttribute('data-longitude');

                    var mapOptions = {
                        zoom: 15,
                        center: new google.maps.LatLng(myLatLngX,myLatLngY),
                        scrollwheel: false,
                        draggable: draggable,
                        disableDefaultUI: true
                    };

                    if (mapObj.classList.contains('map--monocolor')) {
                        mapOptions.styles = [
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            }
                        ];
                    }

                    var mapElement = mapObj;
                    var map = new google.maps.Map(mapElement, mapOptions);
                    var image = static_folder+'/map-marker-red.png';

                    // if (mapObj.classList.contains('map--monocolor')) {
                        var marker = new google.maps.Marker({
                            position: map.getCenter(),
                            icon: {
                                url: image,
                                // scaledSize: new google.maps.Size(48, 48),
                            },
                            map: map,
                            animation: google.maps.Animation.DROP,
                            url: 'http://maps.google.com/?q='+myLatLngX+','+myLatLngY
                        });
                    // }

                    google.maps.event.addDomListener(window, 'resize', function() {
                        var center = map.getCenter();
                        google.maps.event.trigger(map, 'resize');
                        map.setCenter(center);
                    });
                });
        }
        init();
    }
};

CH.carousel = {
    eventGalery: function() {
        let carouselSettings = {
            center: true,
            margin: 40,
            nav: true,
            dots: false,
            lazyLoad: true,
            loop: false,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1.5
                },
                1025: {
                    items: 2.5
                },
            }
        };

        if ($('.js-gallery-slider').length) {
            $('.js-gallery-slider').owlCarousel(carouselSettings);
        }
    },
    eventTimeline: function() {

        let carouselSettings = {
            nav: true,
            dots: false,
            lazyLoad: true,
            loop: false,
            center: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1.5
                },
                1280: {
                    items: 3
                },
            }
        };

        if ($('.js-timeline-slider').length) {
            $('.js-timeline-slider').owlCarousel(carouselSettings);
        }
    }
};

CH.utils.infiniteCallback = function infiniteCallback() {
    CH.refreshLazyLoading()
    CH.utils.animationedSections();
    CH.equalHeights();
};

CH.accordion = function() {

    $('.js-tab-btn').off();
    $('.js-tab-btn').on('click', function(){
        let x = UTILS.screenSize().x;
        let tabTitle = $(this).attr('data-name');
        let goToTeamMemberPosition = $(this).offset().top;

        if ($(this).hasClass('accordion-btn--active')) {
            $(this).removeClass('accordion-btn--active');
            $('[data-content="'+ tabTitle +'"]').slideUp();

            if ( x > 767) {
                $(this).find('.js-tab-btn-info').slideDown();
            }

        } else {
            $('.js-tab-btn').removeClass('accordion-btn--active');
            $(this).addClass('accordion-btn--active');

            $('.js-tab-content:not([data-content="'+ tabTitle +'"])').slideUp();

            setTimeout(function() {
                $('[data-content="'+ tabTitle +'"]').slideDown();
            }, 100);

            if ( x > 767) {
                $('.js-tab-btn').find('.js-tab-btn-info').slideDown();
                $(this).find('.js-tab-btn-info').slideUp();
            }
        }

        $('[data-close="'+ tabTitle +'"]').on('click', function(){
            $('[data-content="'+ tabTitle +'"]').slideUp();
            $('.js-tab-btn').removeClass('accordion-btn--active');

            if ( x > 767) {
                $('.js-tab-btn').find('.js-tab-btn-info').slideDown();
            }
        });

        $('html, body').animate({scrollTop: goToTeamMemberPosition - 100}, 'slow');

    });
};


CH.showDetailsOfPlans = function() {
    let showAllFeaturesBtn = document.querySelector('.js-all-features');
    let showPlanFeaturesBtn = $('.js-plan-features-btn');
    let allPlanFeatures = document.querySelectorAll('.js-plan--feature');

    if (showAllFeaturesBtn) {
        showAllFeaturesBtn.addEventListener('click', function() {
            let goToPlansContainer = $(".js-plans").offset().top;

            if (allPlanFeatures[0].classList.contains("hidden")) {

                [].forEach.call(allPlanFeatures, function(planFeatures) {
                    planFeatures.classList.remove("hidden");
                });
                showAllFeaturesBtn.innerHTML = "Close All Features";
                CH.equalHeights();

            } else {
                [].forEach.call(allPlanFeatures, function(planFeatures) {
                    planFeatures.classList.add("hidden");
                });
                showAllFeaturesBtn.innerHTML = "See All Features";

                $('html, body').animate({scrollTop: goToPlansContainer - 100}, 'slow');
            }
        });

        showPlanFeaturesBtn.on('click', function(){
            let thisPlan = $(this).closest(".plan");
            let goToThisPlan = thisPlan.offset().top;
            let thisPanFeatures = thisPlan.find(".js-plan--feature");

            if ($(thisPanFeatures[0]).hasClass("hidden")) {
                thisPanFeatures.removeClass("hidden");
                $(this).text("Close All Features");
                $(this).addClass("mt-20em");
                CH.equalHeights();

                $('html, body').animate({scrollTop: goToThisPlan - 100}, 'slow');

            } else {
                thisPanFeatures.addClass("hidden");
                $(this).text("See All Features");
                $(this).removeClass("mt-20em");
                $('html, body').animate({scrollTop: goToThisPlan - 100}, 'slow');
            }
        });
    }
};

CH.utils.loadinAnimation = function() {
    let navbar = document.querySelector('.l-navbar');
    
    setTimeout(function(){
        navbar.classList.remove('l-navbar--loading');
    }, 100);
};

CH.openPopup = function() {
    let popupButton = $('.js-popup-btn');

    popupButton.on("click", function() {
        let popupButtonDataName = $(this).attr('data-name');
        let popupDataContent = $(`[data-content="${popupButtonDataName}"]`);
        let popupContentCloseBtn = popupDataContent.find('.popup__close');

        popupDataContent.addClass('popup-wrapper--show');

        popupContentCloseBtn.on("click", function() {
            popupDataContent.removeClass('popup-wrapper--show');
        });
    });

    $(document).on( "mouseup touchend", function (e){
        if (!$('.popup').is(e.target) && $('.popup').has(e.target).length === 0 && $('.popup').parent().hasClass('popup-wrapper--show')){
            $('.popup').parent().removeClass('popup-wrapper--show');
        }
    });
};

CH.showNewsCards = function(){
    let upcomingNewstList = $('.js-upcoming-news').children();
    let x = UTILS.screenSize().x;
    let upcomingNewssBtn = $(".js-upcoming-news-btn");

    if ( x > 1440) {
        for (var i=3; i < upcomingNewstList.length; i++) {
            upcomingNewstList.eq(i).hide();
        }

        for (var i=0; i < 3; i++) {
            upcomingNewstList.eq(i).show();
        }
    } else if (x >= 991 && x <= 1440) {
        for (var i=2; i < upcomingNewstList.length; i++) {
            upcomingNewstList.eq(i).hide();
        }

        for (var i=0; i < 2; i++) {
            upcomingNewstList.eq(i).show();
        }

    } else if ( x < 991) {
        for (var i=1; i < upcomingNewstList.length; i++) {
            upcomingNewstList.eq(i).hide();
        }
    }

    upcomingNewssBtn.on('click', function() {
        upcomingNewstList.each(function(i, el){
            $(el).slideDown();
        });
    });
};


CH.doTransitions = true;
CH.noBarbaTransition = () => {
    $('.no-barba-transition').off();
    $('.no-barba-transition').on('click', function() {
        CH.doTransitions = false;

        let self = $(this),
            url = self.attr('href');

        Barba.Pjax.goTo( url );

        return false;
    });
};


CH.equalHeights = function() {
    $('.js-equal-height').matchHeight();
    $('.js-equal-height-1').matchHeight();
    $('.js-equal-height-2').matchHeight();
    $('.js-equal-height-3').matchHeight();
    $('.js-equal-height-4').matchHeight();
    $('.js-equal-headline').matchHeight();
};

CH.inputMask = function() {
    if (document.querySelector('.masked')) {
        new InputMask();
    }
};

CH.refreshLazyLoading = function() {
    if (window['lazyLoadInstance'] !== undefined) {
        lazyLoadInstance.update();
    }
};

CH.customForm = {

    addValidation: function(checkboxes) {
        const firstCheckbox = CH.customForm.getFirstCheckbox(checkboxes);
    
        if (firstCheckbox) {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].addEventListener('change', function() {
                	CH.customForm.checkValidity(checkboxes, firstCheckbox);
                });
            }

            CH.customForm.checkValidity(checkboxes, firstCheckbox);
        }
    },
    
    getFirstCheckbox: function(checkboxes) {
        return checkboxes.length > 0 ? checkboxes[0] : null;
    },

    isChecked: function(checkboxes) {
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) return true;
        }

        return false;
    },

    checkValidity: function(checkboxes) {
        const firstCheckbox = CH.customForm.getFirstCheckbox(checkboxes);
        const errorMessage = !CH.customForm.isChecked(checkboxes) ? 'At least one checkbox must be selected.' : '';
        firstCheckbox.setCustomValidity(errorMessage);
    },


    toggle: function(id) {
        $(id).slideToggle('slow', function() {
            $(this).toggleClass('open');
            
            if ($(this).hasClass('open')) {
                $(this).find('input, textarea').attr('required', true);
            } else {
                $(this).find('input, textarea').removeAttr('required');
            }
       });
    },

    wordsLimit: function() {

        if (document.querySelector('.js-word-limit')) {
            let allInputs = document.querySelectorAll('.js-word-limit');

            [].forEach.call(allInputs, function (singleInput) {
                const inputLimit = singleInput.dataset.limit*1;

                singleInput.addEventListener('keyup', function() {
                	CH.customForm.wordsLimitCheckValidation(singleInput, inputLimit);
                });
            });
        }
    },

    wordsLimitCheckValidation: function(singleInput, inputLimit) {
        let isValid = true;
        if (singleInput.value.split(' ').length > inputLimit) {
            isValid = false;
        };
        const errorMessage = !isValid ? `Limit of ${inputLimit} words exceeded` : '';
        singleInput.setCustomValidity(errorMessage);
    },

    init: function() {
        if (document.querySelector('.js-form-checkboxes')) {
            let allForms = document.querySelectorAll('.js-form-checkboxes');

            [].forEach.call(allForms, function (singleForm) {
                let checkboxesName = singleForm.dataset.checkboxName;
                const checkboxes = singleForm.querySelectorAll(`input[name="${checkboxesName}"]`);
                CH.customForm.addValidation(checkboxes);
            });
        }

        this.wordsLimit();
    }
};

function trackPageGTM() {
    if (window['dataLayer'] !== undefined) {
        let pagePath = window.location.pathname;

        window.dataLayer.push({
            'event': 'content-view',
            'content-name': pagePath
        });
    }
}

function isAppleDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

/////////////////////////////////////
//
// BARBA.js
//
////////////////////////////////////

function destroyScripts() {
    // Destroy all Waypoints
    Waypoint.destroyAll();
}

function runWebsiteScriptsOnce() {

    CH.menu.submenu();
    CH.menu.closeSubmenuByHitEsc();
    CH.menu.burger();
    CH.menu.closeSubmenuOutsideSubmenuListener();
    CH.menu.setActiveMenuItemFromSubNav();
    CH.menu.setActiveMenuItemFromMainNav();
    CH.menu.closeMenuOnLinkClickOnMobile();
    CH.menu.logo();

    const ua = window.navigator.userAgent.toLowerCase();
    const isIe = (/trident/gi).test(ua) || (/msie/gi).test(ua);

    if (isIe) {
        CH.navbarPosition.ie();
    } else {
        CH.navbarPosition.allBrowsers();
    }
};

function runWebsiteScripts() {
    console.log('RunWebsite Scripts');

    CH.utils.hashAnchorClick();
    console.log('Run Animated Sections');
    CH.utils.animationedSections();
    console.log('Run PopupOnClick');
    CH.utils.openPopupOnClick();
    console.log('Run magnific');
    CH.utils.magnific();
    console.log('Run selectBoxes');
    CH.selectBoxes.init();
    console.log('Run inputLabels');
    CH.utils.inputLabels();
    CH.utils.isSubmenu();
    CH.utils.submenuOnScrollLinkArray = [];
    CH.utils.fillSubmenuLinksArray();
    CH.utils.submenuOnScroll();
    CH.utils.infiniteScroll(CH.utils.infiniteCallback);
    CH.equalHeights();
    CH.showDetailsOfPlans();
    CH.openPopup();
    CH.creditCard.nameOnCard();
    CH.creditCard.numberOnCard();
    CH.creditCard.expiresOnCard();
    CH.utils.loadinAnimation();
    CH.noBarbaTransition();
    CH.inputMask();
    console.log(CH.utils)
    console.log(UTILS)
    CH.utils.recaptcha ? CH.utils.recaptcha.init() : void 0;

    if ($('.js-map').length) {
        const mapWaypoint = new Waypoint({
            element: $('.js-map')[0],
            handler: function(direction) {
                if (direction === 'down') {
                    CH.contact.loadScript();
                }
            },
            offset: '90%'
        });
    }

    if($('.js-show-features').length) {

        $('.js-show-features').on('click', function() {
            let allFeatures = $(this).prev().slideToggle();
        });
    }

    CH.carousel.eventGalery();
    CH.carousel.eventTimeline();
    CH.refreshLazyLoading();
    CH.accordion();
    CH.utils.isConfirmPassword();
    CH.showNewsCards();
    CH.utils.homepageAnimation();
    CH.utils.confirmFormId = '';
    // CH.utils.confirmPopup();

    CH.utils.popupCancel();
    CH.utils.popupConfirm();
    CH.utils.popupClose();

    CH.customForm.init();
};

const FadeTransition = Barba.BaseTransition.extend({
    start: function () {
        Promise
            .all([this.newContainerLoading, this.fadeOut()])
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        /**
         * this.oldContainer is the HTMLElement of the old Container
         */

        var deferred = Barba.Utils.deferred();

        if (CH.doTransitions) {
            return $(this.oldContainer).animate({
                opacity: 0
            }).promise();
        } else {
            deferred.resolve();
        }
    },

    fadeIn: function () {
        function destroyOwlCarousels() {
            if (!($('.owl-loaded').length)) {
                return;
            }

            $('.owl-loaded').trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
            $('.owl-loaded').find('.owl-stage-outer').children().unwrap();
        }

        $(window).scrollTop(0); // scroll to top here
        CH.menu.closeAllSubmenus();
        CH.utils.goToHash();
        destroyOwlCarousels();
        destroyScripts();

        var _this = this;
        var $el = $(this.newContainer);

        $(this.oldContainer).hide();

        if (CH.doTransitions) {

            $el.css({
                visibility: 'visible',
                opacity: 0
            });

            $el.animate({
                opacity: 1
            }, 400, function () {
                /**
                 * Do not forget to call .done() as soon your transition is finished!
                 * .done() will automatically remove from the DOM the old Container
                 */

                _this.done();
            });
        } else {
            _this.done();
            CH.doTransitions = true;
        }
    }
});

Barba.Pjax.getTransition = function () {
    return FadeTransition;
};

Barba.Dispatcher.on('transitionCompleted', function (currentStatus, oldStatus) {
    console.log('barba transition completed + runWebsiteScripts')
    runWebsiteScripts();
});

Barba.Dispatcher.on('newPageReady', function (current, prev, container) {
    console.log('barba newPageReady')
    history.scrollRestoration = 'manual';
    trackPageGTM();
});

$(function () {
    console.log('document ready')
    let barbaContainer = $('.barba-container').length;

    if (barbaContainer) {
        console.log('barba container')
        Barba.Pjax.start();
        Barba.Pjax.getTransition();
        Barba.Prefetch.init();

        document.body.classList.remove('preload');
        runWebsiteScriptsOnce();
    } else {
        console.log('NO barba container')
        runWebsiteScripts();
    }
});

// window.addEventListener('DOMContentLoaded', function() {
//     console.log('DOM Content loaded')

//     let barbaContainer = $('.barba-container').length;

//     if (barbaContainer) {
//         console.log('barba container')
//         Barba.Pjax.start();
//         Barba.Pjax.getTransition();
//         Barba.Prefetch.init();

//         document.body.classList.remove('preload');
//         runWebsiteScriptsOnce();
//     } else {
//         console.log('NO barba container')
//         runWebsiteScripts();
//     }
// })

$(window).on('load', function() {
    setTimeout(function() {
        console.log(CH.utils);
        CH.utils.goToHash();
    }, 200);
});

window.addEventListener('scroll', function (e) {
    CH.utils.submenuOnScroll();

    const ua = window.navigator.userAgent.toLowerCase();
    const isIe = (/trident/gi).test(ua) || (/msie/gi).test(ua);

    if (!isIe) {
        CH.navbarPosition.allBrowsers();
    };

}, false);

window.addEventListener('resize', function() {
    CH.accordion();
    CH.showNewsCards();
}, false);

// UPDATE GOOGLE MAP KEY !!!!!!!!!!!!!!!!!!!!!!!!!!!!!