'use strict';

var UTILS = UTILS || {};

UTILS.submitPayment = function submitPayment(apiUrl) {
    const form = document.querySelector('.js-payment-form');

    const loadingLayer = form.querySelector('.loading');
    loadingLayer.classList.add('loading--active');
    
    const countryField = document.getElementById('country');

    if (countryField) {
        const countryName = countryField.value || '';
    
        return fetch(`/api/shop/check-country?country=${countryName}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => {
            return getStripeIntent({
                country: data.code,
                url: apiUrl
            });
        })
        .catch(() => {
            alert('An error occured. Please contact us via contact form');
            hideLoadingLayer();
        });

    } else {
        return getStripeIntent({
            url: apiUrl
        });
    }

    function getStripeIntent(data = {}) {
        const mainField = document.getElementById('full_name');
        const stripeOptions = {
            type: 'card',
            card: cardNumber,
        };
        let billingDetails = {};

        if (!!mainField) {
            billingDetails = {
                name: document.getElementById('full_name').value || '',
                address: {
                    line1:  `${document.getElementById('business').value} ${document.getElementById('street_name').value} ${document.getElementById('house_number').value}` || '',
                    city: document.getElementById('city').value || '',
                    postal_code: document.getElementById('post_code').value || '',
                    country: data.country || '',
                }
            };

            stripeOptions.billing_details = billingDetails;
        }

        return stripe.createPaymentMethod(stripeOptions).then(function(result) {
            if (result.error) {
                // Show error in payment form
                showErrorMessage({message: result.error.message});
                hideLoadingLayer();
            } else {
                // init the request
                let request = new XMLHttpRequest();
                let payloadData = JSON.stringify({ payment_method_id: result.paymentMethod.id });
    
                request.open('POST', data.url, true);
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                request.setRequestHeader("Content-Type", "application/json");
                request.onload = function() {
                    if (request.status === 500) {
                        let response = { error: true };
                        handleServerResponse(response, data);
                        return false;
                    } else {
                        let response = JSON.parse(request.response);
                        response.paymentMethodId = result.paymentMethod.id;
                        handleServerResponse(response, data);
                        return false;
                    }
                };
    
                // send the request
                request.send(payloadData);
            }
        });
    }

    function handleServerResponse(response, data) {
        if (response.error) {
            // Show error from server on payment form
            showErrorMessage({message: response.message});
            hideLoadingLayer();

        } else if (response.requires_3ds_secure && response.subscription) {

            stripe.confirmCardPayment(response.payment_intent_client_secret, {
                payment_method: response.paymentMethodId,
            }).then(function(result) {

                if (result.error) {
                    showErrorMessage({message: result.error.message});
                    hideLoadingLayer();
                } else {
                    form.submit();
                    return false;
                }
            });

        } else if (response.requires_3ds_secure && !response.subscription) {

            // Use Stripe.js to handle required card action
            stripe.handleCardAction(
                response.payment_intent_client_secret
            ).then(function(result) {
                if (result.error) {
                    // Show error in payment form
                    showErrorMessage({message: result.error.message});
                    hideLoadingLayer();
                } else {
                    // init the request
                    let request = new XMLHttpRequest();
                    let payloadData = JSON.stringify({ payment_intent_id: result.paymentIntent.id });

                    request.open('POST', data.url, true);
                    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                    request.onload = function() {
                        if (request.status >= 200 && request.status < 400) {
                            form.submit();
                            return false;
                        } else {
                            let response = JSON.parse(request.response);
                            handleServerResponse(response, data);
                            return false;
                        }
                    };

                    // send the request
                    request.send(payloadData);
                }
            });
        } else {
            form.submit();
        }
    }

    function showErrorMessage({message}) {
        let errorElement = document.getElementById('card-errors');
        errorElement.textContent = message;
        let responseLayer = document.querySelector('.response.response--error');

        if (errorElement.classList.contains('hidden')) {
            errorElement.classList.remove('hidden');
        };

        if (responseLayer) {
            responseLayer.classList.remove('hidden');
        }
    }

    function hideLoadingLayer() {
        loadingLayer.classList.remove('loading--active');
    }
};