'use strict';

/**
 * 
 * Scroll To ID 
 * jquery needed
 * 
 */

var UTILS = UTILS || {};

UTILS.scrollToID = function scrollToID(id, context = 'html,body') {
    var path = window.location.pathname;
    var offSet = 100;
    var x = UTILS.screenSize().x;

    if (x < 768) {
        offSet = 80;
    }

    var targetOffset = $(id).offset().top - offSet;

    $(context).animate({scrollTop:targetOffset}, 1000, function() {
        setTimeout(function () {
            $('.l-navbar').removeClass('l-navbar--scrolled');
            document.body.classList.remove('page-scrolled');
            $(`.l-submenu__list-link`).removeClass('active');
            $(`.l-submenu__list-link[href="${id}"]`).addClass('active');
        }, 50);
    });
};

UTILS.hashAnchorClick = function hashAnchorClick() {
    $('a[href^="#"]:not(.js-scroll-to)').on('click', function (e) {

        var target = this.hash,
            hashValue = target.substr(target.indexOf("#"));

        if (hashValue.length) {
            UTILS.scrollToID( hashValue );
        }

        return false;
    });

    return false;
};
